import React, {
  createContext,
  useContext,
} from 'react'

const context = createContext({
  requestLanguageChange: (request) => { },
  requestUserRegistration: (request) => { },
  requestUserTokenRenewal: (request) => { },
  requestUserInfoLookup: (request) => { },
  requestUserTokenDisposal: (request) => { },
  requestCxrUserTokenRenewal: (request) => { },
  requestUserOtpRenewal: (request) => { },
  requestUserPasswordRenewal: (request) => { },
  subToLoading: (receiver) => { },
  unsubFromLoading: (receiver) => { },
  subToFailure: (receiver) => { },
  unsubFromFailure: (receiver) => { },
  subToSuccess: (receiver) => { },
  unsubFromSuccess: (receiver) => { },
  subToLexicon: (receiver) => { },
  unsubFromLexicon: (receiver) => { },
  subToRedirect_: (receiver) => { },
  unsubFromRedirect_: (receiver) => { },
  subToRedirect: (receiver) => { },
  unsubFromRedirect: (receiver) => { },
  subToSignedIn: (receiver) => { },
  unsubFromSignedIn: (receiver) => { },
  subToUserInfo: (receiver) => { },
  unsubFromUserInfo: (receiver) => { },
  subToOtpRenewalResult: (receiver) => { },
  unsubFromOtpRenewalResult: (receiver) => { },
  subToOtpSignUpResult: (receiver) => { },
  unsubFromOtpSignUpResult: (receiver) => { },
})

export function HandlerProvider({ handler, children }) {
  return <context.Provider value={handler}>
    {children}
  </context.Provider>
}

export const HandlerConsumer = context.Consumer

export function useHandler() {
  return useContext(context)
}
