import cookies from 'js-cookie'
import texts from './texts.json'
import { parseDomain } from 'parse-domain'

import { USER_OTP_RENEWAL_TYPES } from './react/config'

const Coordinator = (options) => (handler) => {
  if (!options.url) {
    throw Error('some coordinator options are missing')
  }

  const url = options.url
  const signedInKey = `${new URL(url).hostname}_signedIn`

  const loading = ValueNotifier({
    seed: {},
  })
  const failure = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const success = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const redirect_ = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const redirect = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const lexicon = ValueNotifier({
    seed: lexicons[localStorage.getItem('language') ?? 'en-US'],
  })
  const signedIn = ValueNotifier({
    seed: cookies.get(signedInKey),
  })
  const userInfo = ValueNotifier({
    seed: null,
  })
  const otpRenewalResult = ValueNotifier({
    seed: null,
    renotify: false,
  })
  const otpSignUpResult = ValueNotifier({
    seed: null,
    renotify: false,
  })

  const cxrAgreementNeeded = ValueNotifier({
    seed: null,
    renotify: false,
  })

  window.cookieStore?.addEventListener('change', async ({
    changed: changedCookies,
    deleted: deletedCookies,
  }) => {
    if (changedCookies.some(cookie =>
      cookie.name === signedInKey &&
      cookie.value === 'true',
    )) {
      signedIn.value = cookies.get(signedInKey) === 'true'
      requestUserInfoLookup()
      const parameters = new URLSearchParams(window.location.search)
      if (parameters.get('redirect_')) {
        redirect_.value = parameters.get('redirect_')
      } else if (parameters.get('redirect')) {
        redirect.value = parameters.get('redirect')
      }
    }

    if (deletedCookies.some(cookie =>
      cookie.name === signedInKey,
    )) {
      requestUserTokenDisposal()
    }
  })

  async function requestLanguageChange(request) {
    loading.value = {
      ...loading.value,
      requestLanguageChange: true,
    }

    if (lexicons[request]) {
      lexicon.value = lexicons[request]
      localStorage.setItem('language', request)
    }

    loading.value = {
      ...loading.value,
      requestLanguageChange: false,
    }
  }

  async function requestUserRegistration(request) {
    loading.value = {
      ...loading.value,
      requestUserRegistration: true,
    }

    const response = await handler.requestUserRegistration({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
    } else {
      otpSignUpResult.value = response.diff
    }

    loading.value = {
      ...loading.value,
      requestUserRegistration: false,
    }
  }

  async function requestUserEmailVerification(request) {
    loading.value = {
      ...loading.value,
      requestUserEmailVerification: true,
    }

    const response = await handler.requestUserEmailVerification({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
    } else {
      signedIn.value = cookies.get(signedInKey) === 'true'

      if (request.redirect_) {
        success.value = 'sign up successful'
        redirect_.value = request.redirect_
      } else if (request.redirect) {
        success.value = 'sign up successful'
        redirect.value = request.redirect
      } else {
        success.value = 'sign up successful'
        redirect.value = '/'
      }
    }

    loading.value = {
      ...loading.value,
      requestUserEmailVerification: false,
    }
  }

  async function requestUserTokenRenewal(request) {
    loading.value = {
      ...loading.value,
      requestUserTokenRenewal: true,
    }

    const response = await handler.requestUserTokenRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
    } else {
      signedIn.value = cookies.get(signedInKey) === 'true'

      if (request.redirect_) {
        redirect_.value = request.redirect_
      } else if (request.redirect) {
        redirect.value = request.redirect
      } else {
        redirect.value = '/'
      }
    }

    loading.value = {
      ...loading.value,
      requestUserTokenRenewal: false,
    }
  }

  async function requestCxrUserTokenRenewal(request) {
    loading.value = {
      ...loading.value,
      requestCxrUserTokenRenewal: true,
    }

    const response = await handler.requestCxrUserTokenRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok && response.cxrAgreementNeeded) {
      cxrAgreementNeeded.value = response.cxrAgreementNeeded
    } else if (!response.ok) {
      failure.value = response.message
    } else {
      signedIn.value = cookies.get(signedInKey) === 'true'

      if (request.redirect_) {
        redirect_.value = request.redirect_
      } else if (request.redirect) {
        redirect.value = request.redirect
      } else {
        redirect.value = '/'
      }
    }

    loading.value = {
      ...loading.value,
      requestCxrUserTokenRenewal: false,
    }
  }

  async function requestUserInfoLookup() {
    if (signedIn.value) {
      loading.value = {
        ...loading.value,
        requestUserInfoLookup: true,
      }

      const response = await handler.requestUserInfoLookup()

      if (!response.ok && response.unauthorized) {
        requestUserTokenDisposal()
      } else if (!response.ok) {
        failure.value = response.message
      } else {
        userInfo.value = response.data
      }

      loading.value = {
        ...loading.value,
        requestUserInfoLookup: false,
      }
    }
  }

  async function requestUserTokenDisposal() {
    loading.value = {
      ...loading.value,
      requestUserTokenDisposal: true,
    }

    cookies.remove(signedInKey)
    const hostname = window.location.hostname
    const parsedDomain = parseDomain(hostname)
    const domain = parsedDomain.domain ?? ''
    const tlds = parsedDomain.topLevelDomains ?? []
    cookies.remove(signedInKey, {
      domain: [domain, ...tlds].join('.'),
    })
    signedIn.value = null

    loading.value = {
      ...loading.value,
      requestUserTokenDisposal: false,
    }
  }

  async function requestUserOtpRenewal(request) {
    loading.value = {
      ...loading.value,
      requestUserOtpRenewal: true,
    }

    const response = await handler.requestUserOtpRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
    } else {
      if (request.type === USER_OTP_RENEWAL_TYPES.REGISTER) {
        success.value = 'new OTP has been resent'
        otpSignUpResult.value = response.diff
      } else if (request.type === USER_OTP_RENEWAL_TYPES.FORGOT_PASSWORD) {
        success.value = 'new OTP has been resent'
      }
      otpRenewalResult.value = response.diff
    }

    loading.value = {
      ...loading.value,
      requestUserOtpRenewal: false,
    }
  }

  async function requestUserPasswordRenewal(request) {
    loading.value = {
      ...loading.value,
      requestUserPasswordRenewal: true,
    }

    const response = await handler.requestUserPasswordRenewal({
      ...request,
    })

    if (!response.ok && response.unauthorized) {
      requestUserTokenDisposal()
    } else if (!response.ok) {
      failure.value = response.message
    } else {
      redirect.value = '/login'
    }

    loading.value = {
      ...loading.value,
      requestUserPasswordRenewal: false,
    }
  }

  return {
    ...handler,
    requestLanguageChange,
    requestUserRegistration,
    requestUserEmailVerification,
    requestUserTokenRenewal,
    requestCxrUserTokenRenewal,
    requestUserInfoLookup,
    requestUserTokenDisposal,
    requestUserOtpRenewal,
    requestUserPasswordRenewal,
    subToLoading: loading.subscribe,
    unsubFromLoading: loading.unsubscribe,
    subToFailure: failure.subscribe,
    unsubFromFailure: failure.unsubscribe,
    subToSuccess: success.subscribe,
    unsubFromSuccess: success.unsubscribe,
    subToLexicon: lexicon.subscribe,
    unsubFromLexicon: lexicon.unsubscribe,
    subToRedirect_: redirect_.subscribe,
    unsubFromRedirect_: redirect_.unsubscribe,
    subToRedirect: redirect.subscribe,
    unsubFromRedirect: redirect.unsubscribe,
    subToSignedIn: signedIn.subscribe,
    unsubFromSignedIn: signedIn.unsubscribe,
    subToUserInfo: userInfo.subscribe,
    unsubFromUserInfo: userInfo.unsubscribe,
    subToOtpRenewalResult: otpRenewalResult.subscribe,
    unsubFromOtpRenewalResult: otpRenewalResult.unsubscribe,
    subToOtpSignUpResult: otpSignUpResult.subscribe,
    unsubFromOtpSignUpResult: otpSignUpResult.unsubscribe,
    subToCxrAgreementNeeded: cxrAgreementNeeded.subscribe,
    unsubFromCxrAgreementNeeded: cxrAgreementNeeded.unsubscribe,
  }
}

const lexicons = Object.entries(texts)
  .reduce((previous, [key, value]) => {
    Object.entries(value)
      .forEach(([language, value]) => {
        if (!previous[language]) previous[language] = {}
        previous[language].id = language
        previous[language][key] = value
      })
    return previous
  }, {})

function ValueNotifier({ seed, renotify = true }) {
  let value
  const receivers = new Set([]);
  (async () => {
    value = await seed
    receivers.forEach(receiver => receiver(value))
  })()

  return {
    get value() {
      return value
    },
    set value(newValue) {
      value = newValue
      receivers.forEach(receiver => receiver(newValue))
    },
    subscribe: (receiver) => {
      renotify && receiver(value)
      receivers.add(receiver)
    },
    unsubscribe: (receiver) => {
      receivers.delete(receiver)
    },
  }
}

export default Coordinator
