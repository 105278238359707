const Server = (options) => {
  if (!options.url) {
    throw Error('some server options are missing')
  }

  const url = options.url
  const utf8Encoder = new TextEncoder()

  async function requestUserRegistration(request) {
    try {
      const endpoint = `${url}/user-registration`
      const response = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(request),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong',
        cause: error,
      }
    }
  }

  async function requestUserEmailVerification(request) {
    try {
      const endpoint = `${url}/user-email-verification`
      const response = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(request),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong',
        cause: error,
      }
    }
  }

  async function requestUserTokenRenewal(request) {
    try {
      const endpoint = `${url}/user-token-renewal`
      const response = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(request),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong',
        cause: error,
      }
    }
  }

  async function requestCxrUserTokenRenewal(request) {
    try {
      const endpoint = `${url}/cxr-user-token-renewal`
      const response = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(request),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong',
        cause: error,
      }
    }
  }

  async function requestUserInfoLookup(request) {
    try {
      const endpoint = `${url}/user-info-lookup`
      const inlined = JSON.stringify(request ?? {})
      const digest = await window.crypto.subtle
        .digest('SHA-1', utf8Encoder.encode(inlined))
        .then(buffer => Array.from(new Uint8Array(buffer)))
        .then(bytes => bytes.map(value => String.fromCharCode(value)))
        .then(characters => window.btoa(characters.join('')))
      const response = await fetch(`${endpoint}?digest=${digest}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Inlined-Body': inlined,
        },
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong',
        cause: error,
      }
    }
  }

  async function requestUserOtpRenewal(request) {
    try {
      const endpoint = `${url}/user-otp-renewal`
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(request),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  async function requestUserPasswordRenewal(request) {
    try {
      const endpoint = `${url}/user-password-renewal`
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(request),
      }).then(response => response.json())

      return response
    } catch (error) {
      return {
        ok: false,
        message: 'sorry, something went wrong'
      }
    }
  }

  return {
    requestUserRegistration,
    requestUserEmailVerification,
    requestUserTokenRenewal,
    requestCxrUserTokenRenewal,
    requestUserInfoLookup,
    requestUserOtpRenewal,
    requestUserPasswordRenewal,
  }
}

export default Server
