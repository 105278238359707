import { useCallback, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useHandler } from './handler_provider'
import Loading from './loading'
import Notifier from './notifier'

function Root() {
  const handler = useHandler()
  const navigate = useNavigate()
  const navigate_ = useCallback(url => {
    window.location.assign(url)
  }, [])
  useEffect(() => {
    handler.subToRedirect(navigate)
    return () => handler.unsubFromRedirect(navigate)
  }, [handler, navigate])
  useEffect(() => {
    handler.subToRedirect_(navigate_)
    return () => handler.unsubFromRedirect(navigate_)
  }, [handler, navigate_])

  return <>
    <Notifier />
    <Loading />
    <Outlet />
  </>
}

export default Root
