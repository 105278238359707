import {
  FilePdfOutlined,
  LockOutlined,
  MailOutlined,
  // ShopOutlined,
  UserOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal } from 'antd'
import React, {
  useEffect,
  useState,
  useRef,
} from 'react'
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  Link,
  useSearchParams,
  useLocation,
} from 'react-router-dom'
import Countdown, { zeroPad } from 'react-countdown'

import { useHandler } from './handler_provider'
import logo from './images/dark-logo.webp'

import { USER_OTP_RENEWAL_TYPES } from './config'

function Register() {
  const handler = useHandler()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const [lexicon, setLexicon] = useState({})
  const [otpSignUpResult, setOtpSignUpResult] = useState(null)
  const [open, setOpen] = useState(false)

  const inputOtpRef = useRef()
  const countDownRef = useRef()

  const [form] = Form.useForm()

  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])

  useEffect(() => {
    const setValidOtpSignUpResult = (result) => {
      result && setOtpSignUpResult(result)
    }
    handler.subToOtpSignUpResult(setValidOtpSignUpResult)
    return () => handler.unsubFromOtpSignUpResult(setValidOtpSignUpResult)
  }, [handler])

  useEffect(() => {
    if (otpSignUpResult && countDownRef && countDownRef.current) {
      countDownRef.current.start()
    }
  }, [otpSignUpResult, countDownRef])

  // const [phone, setPhone] = useState({
  //   phoneCode: '62',
  //   phoneNumber: '',
  // })
  // const handlePhoneNumberChange = (code, number) => {
  //   setPhone({
  //     phoneCode: code,
  //     phoneNumber: number,
  //   })
  // }

  const toLoginLocation = location.search ? `/login${location.search}` : '/login'

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className='d-flex justify-content-between'>
          <p className="mb-0">
            Time remaining: {zeroPad(minutes)}:{zeroPad(seconds)}
          </p>
          <button
            type="button"
            className="fw-semibold text-primary text-decoration-underline"
            aria-disabled={false}
            onClick={() => {
              const emailValue = otpSignUpResult?.email
              if (!emailValue) {
                return null
              } else {
                handler.requestUserOtpRenewal({
                  email: emailValue,
                  type: USER_OTP_RENEWAL_TYPES.REGISTER,
                })
                form.setFieldsValue({ otp: '' })
                inputOtpRef?.current?.focus({
                  cursor: 'start',
                })
              }
            }}
          >
            {lexicon.resend}
          </button>
        </div>
      )
    } else {
      return (
        <div className='d-flex justify-content-between'>
          <p className="mb-0">Time remaining: {zeroPad(minutes)}:{zeroPad(seconds)}</p>
          <button
            type="button"
            className="fw-semibold text-primary text-decoration-underline text-muted"
            aria-disabled={true}
            disabled={true}
          >
            {lexicon.resend}
          </button>
        </div >
      )
    }
  }

  const Content = (page) => {
    switch (page) {
      case 0:
        return (
          <>
            <div className="text-center mt-2">
              <h5 className="text-primary text-base">
                {lexicon.welcome_to_tru_carbon}
              </h5>
              <p className="text-muted text-base">
                {lexicon.create_new_account}
              </p>
            </div>
            <div className="p-2 mt-4">
              <Form
                layout="vertical"
                form={form}
                name="register"
                onFinish={(values) => {
                  handler.requestUserRegistration({
                    email: values.email,
                    name: values.name,
                    // firstName: values.firstName,
                    // lastName: values.lastName,
                    // phone: `+${phone.phoneCode}${phone.phoneNumber}`,
                    password: values.password,
                    serviceAgreement: values.serviceAgreement,
                  })
                }}
                initialValues={{
                  // residence: ["zhejiang", "hangzhou", "xihu"],
                  // prefix: "86",
                  // phone: {
                  // countryCode: `${countryCode}`
                  // }
                }}
                style={{
                  maxWidth: 600,
                }}
                scrollToFirstError
              >
                <Form.Item
                  className='mb-2'
                  name="email"
                  label={lexicon.email}
                  rules={[
                    {
                      type: 'email',
                      message: 'Please enter an email that is correctly formatted',
                    },
                    {
                      required: true,
                      message: 'Please enter your Email',
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <MailOutlined
                        style={{ color: 'lightGray' }}
                        className="site-form-item-icon"
                      />
                    }
                    placeholder={lexicon.email}
                  />
                </Form.Item>
                <Form.Item
                  className='mb-2'
                  name="name"
                  label={lexicon.name}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Name',
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <UserOutlined
                        style={{ color: 'lightGray' }}
                        className="site-form-item-icon"
                      />
                    }
                    placeholder={lexicon.name}
                  />
                </Form.Item>
                {/* <Form.Item
                      name="firstName"
                      label={lexicon.first_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your First Name",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder={lexicon.first_name}
                      />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      label={lexicon.last_name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Last Name",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined
                            style={{ color: "lightGray" }}
                            className="site-form-item-icon"
                          />
                        }
                        placeholder={lexicon.last_name}
                      />
                    </Form.Item>
                    <Form.Item
                      name='phone'
                      label={lexicon.phone_number}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your Phone Number',
                        },
                      ]}
                    >
                      <PhoneInput
                        id="phone"
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true
                        }}
                        inputClass="w-full"
                        inputStyle={{ width: '100%' }}
                        prefix={''}
                        country={'id'}
                        placeholder={lexicon.phone_number}
                        type='text'
                        disabled={false}
                        autoFormat={false}
                        enableSearch={false}
                        onChange={(value, data) => {
                          handlePhoneNumberChange(
                            data.dialCode,
                            (
                              value.length && value.substring(0, 1) === '0' ?
                                value
                                :
                                value.replace(/[^0-9]+/g, '').slice(data.dialCode.length)
                            )
                          )
                        }
                        }
                      />
                    </Form.Item> */}

                <Form.Item
                  className='mb-2'
                  name="password"
                  label={lexicon.password}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password',
                    },
                    {
                      min: 6,
                      message: 'Please enter a password that is more than 6 characters',
                    },
                  ]}
                  hasFeedback
                >

                  <Input.Password
                    prefix={
                      <LockOutlined
                        style={{ color: 'lightGray' }}
                        className="site-form-item-icon"
                      />
                    }
                    type="password"
                    placeholder={lexicon.password}
                  />
                </Form.Item>

                <Form.Item
                  className='mb-0'
                  name="termsAndConditions"
                  valuePropName="checked"
                  rules={[
                    {
                      message: 'Please accept the terms and conditions',
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            'terms and conditions has not been accepted',
                          )
                        } else {
                          return Promise.resolve()
                        }
                      },
                    }
                  ]}
                  hasFeedback
                >
                  <Checkbox>
                    I agree to the&nbsp;
                    <a
                      href='https://truclimate.earth/terms-conditions'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <span className='text-blue-900 underline'>
                        Terms & Conditions
                      </span>
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  className='mb-0'
                  name="privacyPolicy"
                  valuePropName="checked"
                  rules={[
                    {
                      message: 'Please agree to the privacy policy',
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            'privacy policy has not been agreed to',
                          )
                        } else {
                          return Promise.resolve()
                        }
                      },
                    }
                  ]}
                  hasFeedback
                >
                  <Checkbox>
                    I agree to the&nbsp;
                    <a
                      href='https://truclimate.earth/privacy-policy'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <span className='text-blue-900 underline'>
                        Privacy Policy
                      </span>
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  // className='mb-3'
                  name="serviceAgreement"
                  valuePropName="checked"
                  getValueFromEvent={event => {
                    event.target.checked && setOpen(true)
                    return false
                  }}
                  rules={[
                    {
                      message: 'Please accept the service agreement',
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            'service agreement has not been accepted',
                          )
                        } else {
                          return Promise.resolve()
                        }
                      },
                    }
                  ]}
                  hasFeedback
                >
                  <Checkbox>
                    I accept the&nbsp;
                    <a
                      href='/assets/documents/service_agreement.pdf'
                      target='_blank'
                    >
                      <span className='text-blue-900 underline'>
                        Service Agreement
                      </span>
                    </a>
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button className="bg-[#0ab39c]" type="primary" htmlType="submit" block >
                    {lexicon.sign_up}
                  </Button>
                </Form.Item>
              </Form>
              <Modal
                open={open}
                closable={false}
                maskClosable={false}
                onCancel={() => setOpen(false)}
                className="[&_.ant-modal-content]:p-0 min-w-[52%]"
                title={null}
                footer={null}
              // bodyStyle={{ top: 100 }}
              >
                <div
                  className={[
                    'flex',
                    'flex-col',
                    'gap-2',
                  ].join(' ')}
                >
                  <h1
                    className={[
                      'p-3',
                      'bg-[#0ab39c]',
                      'rounded-t-lg',
                      'font-semibold',
                      'text-white',
                    ].join(' ')}
                  >
                    📃 Service Agreement
                  </h1>
                  <div
                    className={[
                      'p-3',
                      'flex-1',
                      // 'bg-violet-300',
                      'text-violet-500',
                    ].join(' ')}
                  >
                    <iframe
                      id='agreement'
                      className='w-full h-[50vh]'
                      title='service agreement'
                      src='/assets/documents/service_agreement.html'
                    />
                  </div>
                  <div className={[
                    'p-3',
                    'flex',
                    'flex-row',
                  ].join(' ')}>
                    <div className={[
                      'flex',
                      'flex-row',
                      'gap-1',
                      'items-center',
                      'font-semibold',
                      'text-[#0ab39c]',
                    ].join(' ')}>
                      <FilePdfOutlined className={[
                        'flex',
                        'flex-row',
                        'items-center',
                        'text-2xl',
                      ].join(' ')} />
                      <a
                        className={[
                          'flex',
                          'flex-row',
                          'items-center',
                          'hover:text-[#0ab39c]'
                        ].join(' ')}
                        href='/assets/documents/service_agreement.pdf'
                        download={true}
                      >
                        Download
                      </a>
                    </div>
                    <div className='flex-1' />
                    <div className='flex flex-row gap-3'>
                      <button
                        className={[
                          'px-4',
                          'py-1',
                          'border-2',
                          'border-[#0ab39c]',
                          'rounded-2xl',
                          'text-[#0ab39c]',
                          'font-semibold',
                        ].join(' ')}
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        Decline
                      </button>
                      <button
                        className={[
                          'px-4',
                          'py-1',
                          'bg-[#0ab39c]',
                          'rounded-2xl',
                          'text-white',
                          'font-semibold',
                          'shadow-md',
                          'shadow-cyan-500/20',
                        ].join(' ')}
                        onClick={() => {
                          form.setFieldValue('serviceAgreement', true)
                          form.validateFields()
                          setOpen(false)
                        }}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </>
        )
      case 1:
        return (
          <>
            <div className="text-center mt-2">
              <h5 className="text-primary text-base">
                {lexicon.verify_email}
              </h5>
              <lord-icon
                src={'https://cdn.lordicon.com/rhvddzym.json'}
                trigger="loop"
                colors="primary:#0ab39c"
                className="avatar-xl"
                style={{ height: '100px', width: '100px' }}
              ></lord-icon>
            </div>

            <div
              className="alert alert-borderless alert-warning text-center text-base mb-2 mx-2"
              role="alert"
            >
              <p>{lexicon.please_enter_the_OTP_code_sent_to} {' '}
                <span className="fw-bold">
                  {otpSignUpResult?.email}
                </span>
              </p>
            </div>

            <div className="p-2">
              <Form
                layout="vertical"
                name="register"
                className="login-form"
                form={form}
                onFinish={(values) => {
                  if (
                    !otpSignUpResult?.email ||
                    !values.otp
                  ) {
                    return null
                  } else {
                    handler.requestUserEmailVerification({
                      email: otpSignUpResult.email,
                      otp: values.otp,
                      ...(searchParams.get('redirect_') && {
                        redirect_: searchParams.get('redirect_'),
                      }),
                      ...(searchParams.get('redirect') && {
                        redirect: searchParams.get('redirect'),
                      }),
                    })
                  }
                }}
              >
                <Form.Item
                  label="OTP"
                  name="otp"
                  className='mb-2'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your OTP!',
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <FieldTimeOutlined
                        style={{ color: 'lightGray' }}
                        className="site-form-item-icon"
                      />
                    }
                    type="text"
                    placeholder="OTP"
                    ref={inputOtpRef}
                  />
                </Form.Item>
                <Form.Item>
                  {otpSignUpResult ? (
                    <Countdown
                      key={new Date(otpSignUpResult.otpExpiry)}
                      ref={countDownRef}
                      date={new Date(otpSignUpResult.otpExpiry)}
                      renderer={renderer}
                      autoStart={false}
                    >
                    </Countdown>
                  ) : (
                    <></>
                  )}
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button bg-[#0ab39c]"
                    block
                  >
                    {lexicon.sign_up}
                  </Button>
                </Form.Item>
              </Form>
            </div >
          </>
        )
      default:
        <></>
    }
  }

  return <div className="auth-page-wrapper pt-5">
    <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div className="bg-overlay"></div>

      <div className="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1440 120"
        >
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <div className="auth-page-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img className="max-h-5" src={logo} alt="" height="20" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card mt-4">
              <div className="card-body p-4">
                <Content />
                {Content(!otpSignUpResult ? 0 : 1)}
              </div>
            </div>

            <div className="mt-4 text-center">
              <p className="mb-0">
                {lexicon.already_have_an_account}? {' '}
                <Link
                  to={toLoginLocation}
                  className="fw-semibold text-primary text-decoration-underline"
                >
                  {lexicon.sign_in}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Register
