import { useEffect } from 'react'
import { useHandler } from './handler_provider'
import { notification } from 'antd'

function Notifier() {
  const handler = useHandler()
  useEffect(() => {
    handler.subToFailure(notifyOfError)
    return () => handler.unsubFromFailure(notifyOfError)
  }, [handler])
  useEffect(() => {
    handler.subToSuccess(notifyOfSuccess)
    return () => handler.unsubFromSuccess(notifyOfSuccess)
  }, [handler])

  return <></>
}

function notifyOfError(message) {
  notification.error({
    message,
    placement: 'topRight',
    key: 'not_nice',
    onClose: null,
  })
}

function notifyOfSuccess(message) {
  notification.success({
    message,
    placement: 'topRight',
    key: 'nice',
    onClose: null,
  })
}

export default Notifier
