import {
  FilePdfOutlined
} from '@ant-design/icons'
import { Button, Checkbox, Form, Modal } from 'antd'
import React, {
  useEffect,
  useRef,
  useState
} from 'react'
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  Link
} from 'react-router-dom'

import { useHandler } from './handler_provider'
import logo from './images/dark-logo.webp'

function CxrLogin() {
  const cxrSessionSharingUrl = new URL(process.env.REACT_APP_CXR_SESSION_SHARING_URL)

  const handler = useHandler()

  const [cxrToken, setCxrToken] = useState()
  const [cxrAgreementNeeded, setCxrAgreementNeeded] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [lexicon, setLexicon] = useState({})
  const [open, setOpen] = useState(false)

  const [form] = Form.useForm()
  const frame = useRef()

  useEffect(() => {
    const setValidCxrAgreementNeeded = (result) => {
      result && setCxrAgreementNeeded(result)
    }
    handler.subToCxrAgreementNeeded(setValidCxrAgreementNeeded)
    return () => handler.unsubFromCxrAgreementNeeded(setValidCxrAgreementNeeded)
  }, [handler])

  const [ready, setReady] = useState(false)
  useEffect(() => {
    const listener = (event) => {
      if (
        event.origin === cxrSessionSharingUrl.origin
        && event.data === 'ok'
      ) {
        frame?.current?.contentWindow?.postMessage(
          { type: 'session-token-lookup' },
          cxrSessionSharingUrl.origin,
        )
      } else if (
        event.origin === cxrSessionSharingUrl.origin
        && event.data?.type === 'session-token-lookup'
        && event.data?.token
      ) {
        console.log('this is the response event: ', event)
        setCxrToken(event.data.token)
      } else {
        console.log('unknown message', event.data)
      }
    }

    window.addEventListener('message', listener, false)
    setReady(true)
    return () => {
      window.removeEventListener('message', listener)
      setReady(false)
    }
  }, [])

  useEffect(() => {
    handler.subToLexicon(setLexicon)
    return () => handler.unsubFromLexicon(setLexicon)
  }, [handler])

  useEffect(() => {
    if (cxrToken) {
      handler.requestCxrUserTokenRenewal({
        token: cxrToken,
      })
    }
  }, [handler, cxrToken])

  return ready && <>
    <iframe
      ref={frame}
      title='session-sharing'
      src={cxrSessionSharingUrl.toString()}
      height='400px'
      width='100%'
      className='hidden'
    >
    </iframe>
    {
      cxrAgreementNeeded
      && (
        <div className="auth-page-wrapper pt-5">
          <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div className="bg-overlay"></div>

            <div className="shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 1440 120"
              >
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
              </svg>
            </div>
          </div>

          <div className="auth-page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img className="max-h-5" src={logo} alt="" height="20" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card mt-4">
                    <div className="card-body p-4">
                      <div className="p-2">
                        <Form
                          layout="vertical"
                          form={form}
                          name="register"
                          onFinish={(values) => {
                            handler.requestCxrUserTokenRenewal({
                              token: cxrToken,
                              serviceAgreement: true,
                            })
                          }}
                          style={{
                            maxWidth: 600,
                          }}
                        >
                          <Form.Item
                            className='mb-0'
                            name="termsAndConditions"
                            valuePropName="checked"
                            rules={[
                              {
                                message: 'Please accept the terms and conditions',
                                validator: (_, value) => {
                                  if (!value) {
                                    return Promise.reject(
                                      'terms and conditions has not been accepted',
                                    )
                                  } else {
                                    return Promise.resolve()
                                  }
                                },
                              }
                            ]}
                            hasFeedback
                          >
                            <Checkbox>
                              I agree to the&nbsp;
                              <a
                                href='https://truclimate.earth/terms-conditions'
                                target='_blank'
                                rel='noreferrer'
                              >
                                <span className='text-blue-900 underline'>
                                  Terms & Conditions
                                </span>
                              </a>
                            </Checkbox>
                          </Form.Item>

                          <Form.Item
                            className='mb-0'
                            name="privacyPolicy"
                            valuePropName="checked"
                            rules={[
                              {
                                message: 'Please agree to the privacy policy',
                                validator: (_, value) => {
                                  if (!value) {
                                    return Promise.reject(
                                      'privacy policy has not been agreed to',
                                    )
                                  } else {
                                    return Promise.resolve()
                                  }
                                },
                              }
                            ]}
                            hasFeedback
                          >
                            <Checkbox>
                              I agree to the&nbsp;
                              <a
                                href='https://truclimate.earth/privacy-policy'
                                target='_blank'
                                rel='noreferrer'
                              >
                                <span className='text-blue-900 underline'>
                                  Privacy Policy
                                </span>
                              </a>
                            </Checkbox>
                          </Form.Item>

                          <Form.Item
                            // className='mb-3'
                            name="serviceAgreement"
                            valuePropName="checked"
                            getValueFromEvent={event => {
                              event.target.checked && setOpen(true)
                              return false
                            }}
                            rules={[
                              {
                                message: 'Please accept the service agreement',
                                validator: (_, value) => {
                                  if (!value) {
                                    return Promise.reject(
                                      'service agreement has not been accepted',
                                    )
                                  } else {
                                    return Promise.resolve()
                                  }
                                },
                              }
                            ]}
                            hasFeedback
                          >
                            <Checkbox>
                              I accept the&nbsp;
                              <a
                                href='/assets/documents/service_agreement.pdf'
                                target='_blank'
                              >
                                <span className='text-blue-900 underline'>
                                  Service Agreement
                                </span>
                              </a>
                            </Checkbox>
                          </Form.Item>

                          <Form.Item className='mb-0'>
                            <Button className="bg-[#3EB6AE]" type="primary" htmlType="submit" block>
                              {lexicon.sign_up}
                            </Button>
                          </Form.Item>
                        </Form>
                        <Modal
                          open={open}
                          closable={false}
                          maskClosable={false}
                          onCancel={() => setOpen(false)}
                          className="[&_.ant-modal-content]:p-0 min-w-[52%]"
                          title={null}
                          footer={null}
                        // bodyStyle={{ top: 100 }}
                        >
                          <div
                            className={[
                              'flex',
                              'flex-col',
                              'gap-2',
                            ].join(' ')}
                          >
                            <h1
                              className={[
                                'p-3',
                                'bg-[#3EB6AE]',
                                'rounded-t-lg',
                                'font-semibold',
                                'text-white',
                              ].join(' ')}
                            >
                              📃 Service Agreement
                            </h1>
                            <div
                              className={[
                                'p-3',
                                'flex-1',
                                // 'bg-violet-300',
                                'text-violet-500',
                              ].join(' ')}
                            >
                              <iframe
                                id='agreement'
                                className='w-full h-[50vh]'
                                title='service agreement'
                                src='assets/documents/service_agreement.html'
                              />
                            </div>
                            <div className={[
                              'p-3',
                              'flex',
                              'flex-row',
                            ].join(' ')}>
                              <div className={[
                                'flex',
                                'flex-row',
                                'gap-1',
                                'items-center',
                                'font-semibold',
                                'text-[#3EB6AE]',
                              ].join(' ')}>
                                <FilePdfOutlined className={[
                                  'flex',
                                  'flex-row',
                                  'items-center',
                                  'text-2xl',
                                ].join(' ')} />
                                <a
                                  className={[
                                    'flex',
                                    'flex-row',
                                    'items-center',
                                    'hover:text-[#3EB6AE]'
                                  ].join(' ')}
                                  href='assets/documents/service_agreement.pdf'
                                  download={true}
                                >
                                  Download
                                </a>
                              </div>
                              <div className='flex-1' />
                              <div className='flex flex-row gap-3'>
                                <button
                                  className={[
                                    'px-4',
                                    'py-1',
                                    'border-2',
                                    'border-[#3EB6AE]',
                                    'rounded-2xl',
                                    'text-[#3EB6AE]',
                                    'font-semibold',
                                  ].join(' ')}
                                  onClick={() => {
                                    setOpen(false)
                                  }}
                                >
                                  Decline
                                </button>
                                <button
                                  className={[
                                    'px-4',
                                    'py-1',
                                    'bg-[#3EB6AE]',
                                    'rounded-2xl',
                                    'text-white',
                                    'font-semibold',
                                    'shadow-md',
                                    'shadow-cyan-500/20',
                                  ].join(' ')}
                                  onClick={() => {
                                    form.setFieldValue('serviceAgreement', true)
                                    form.validateFields()
                                    setOpen(false)
                                  }}
                                >
                                  Accept
                                </button>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

  </>
}

export default CxrLogin
